<template>
  <div lol-end>
    <HomeHeader />
    <DiscordSection />
    <SpecialLeague />
    <CommunityLeague v-show="false" />
    <HistorySection />
  </div>
</template>

<script>
import DiscordSection from '@/views/components/end/DiscordSection.vue';
import SpecialLeague from '@/views/components/end/SpecialLeague.vue';
import CommunityLeague from '@/views/components/end/CommunityLeague.vue';
import HistorySection from '@/views/components/end/HistorySection.vue';
import HomeHeader from '@/views/components/gameHome/HomeHeader.vue';
import meta from '@/data/meta';

export default {
  name: 'LolEnd',
  components: { SpecialLeague, DiscordSection, CommunityLeague, HistorySection, HomeHeader },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';
[lol-end] { .font-g;
  [history-section] {
    .contents-wrapper {
      .contents-holder {
        .box {
          .detail {
            h3 { .c(#df3a3a);}
          }
          &.donation {
            .detail {
              h3 { .c(#143193);}
            }
          }
        }
      }
    }

  }
}

</style>
